#mainbar {
  height: 100%;
}

.tags-page {
  display: inline-block;
  width: 60%;
  margin-left: 20%;
  padding: 24px 0;

  .headline {
    font-size: 28px;
    margin-bottom: 16px;
    line-height: 1.3;
    font-weight: 400;
    padding-left: 16px;
    padding-right: 16px;
  }

  .fs-body {
    padding-left: 16px;
    padding-right: 1rem;
    width: auto;
    font-size: 15px;
    font-weight: 400;
  }

  .headline-count {
    margin-bottom: 12px;
    display: flex;
    padding-left: 16px;
    padding-right: 1rem;

    span {
      font-size: 17px;
      margin-right: 1rem;
    }
  }

  .tags-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: space-between;
  }

  .user-browser {
    float: none;
    clear: both;
    width: auto;
    padding: 0 16px 16px 16px;

    .grid-layout {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    }
  }
}

.Mui-selected {
  color: #000 !important;
  font-weight: 500 !important;
  border-color: transparent !important;
  background-color: #f48225 !important;
}

@media(max-width: 1100px) {
  .side-bar {
    display: none;
  }
  .tags-page {
    width: 80%;
  }
  #content {
    max-width: none;
    margin-right: 10px;
  }
}

@media (max-width: 500px) {
  #mainbar.tags-page {
    .tags-box {
      display: flex;
      align-items: space-between;
    }
    .s-input {
      margin-bottom: 1rem;
    }
  }
}
