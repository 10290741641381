.answer-layout {
  display: grid;
  grid-template-columns: max-content 1fr;

  .vote-cell {
    width: auto;
    padding-right: 16px;
    vertical-align: top;
    grid-column: 1;
    font-size: 13px;

    .vote-container {
      margin: -2px;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      color: #bbc0c4 !important;

      button {
        padding: 0;
        border: none;
        outline: none;
        font: unset;
        border-radius: 0;
        background: none;
        box-shadow: none;
        text-align: center;
        text-decoration: none;
      }
      .vote-count {
        font-size: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#6a737c;
      }

    }
  }

  .answer-item {
    padding-right: 16px;
    grid-column: 2;
    width: auto;
    min-width: 0;
    font-size: 13px;

    .answer-content {
      width: 100%;
      font-size: 15px;
      margin-bottom: 5px;
      font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
      color: #fff;
      p {
        clear: both;
        margin-bottom: 15px;
        margin-top: 0;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
      }
    }

    .answer-actions {
      margin-bottom: 0 !important;
      margin-top: -4px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end !important;
      flex-wrap: wrap !important;

      .action-btns {
        flex: 1 1 100px;
        margin: 4px 16px 4px 0;
        color: #242729;

        .answer-menu {
          padding-top: 2px;

          .s-link .s-link__danger {
            padding: 0 4px 2px;
          }

          .answer-links {
            padding: 0 4px 2px;
            color: #848d95;
            text-decoration: none;

            &:hover {
              color: #cfd2d6;
              text-decoration: none;
            }
          }
        }
      }

      .answer-owner {
        margin-top: 4px;
        margin-bottom: 4px;
        text-align: left;
        vertical-align: top;
        width: 200px;
        flex: 0 auto !important;

        .answer-user {
          box-sizing: border-box;
          padding: 5px 6px 7px 7px;
          color: #6a737c;
          font-size: 13px;

          .answer-user-time {
            margin-top: 1px;
            margin-bottom: 4px;
            font-size: 12px;
            white-space: nowrap;
          }

          .answer-logo {
            float: left;
            width: 32px;
            height: 32px;
            border-radius: 1px;

            img {
              width: 32px;
              height: 32px;
            }
          }

          .answer-details {
            margin-left: 8px;
            width: calc(100% - 40px);
            float: left;

            .answer-user-profile-link {
              color: #0077cc;
              text-decoration: none;
              cursor: pointer;
              font-size: 13px;

              &:hover {
                color: #0095ff;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}