.post-cell {
  display: flex;
  flex-direction: column;
  vertical-align: top;
  padding-right: 16px;
  grid-column: 2;
  width: auto;
  min-width: 0;

  pre {
    color: var(--black-800);
  }

  .post-text {
    width: 100%;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 1.3;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: #fff;
  }

  .post-tags {
    margin-bottom: 10px;
    clear: both;

    .tag-cell {
      display: block;
    }
  }

  .post-actions {
    margin-bottom: 0;

    .post-actions-extended {
      padding-top: 4px;
      margin-top: 16px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .post-btns {
        flex: 1 1 100px;
        margin-top: 4px;
        margin-bottom: 4px;
        margin-right: 16px !important;

        .post-menu {
          padding-top: 2px;

          .post-links {
            padding: 0 4px 2px 4px;
            color: #848d95;
            cursor: pointer;
            text-decoration: none;
            font-size: 13px;

            &:hover {
              color: #cfd2d6;
            }
          }
        }
      }
    }
  }
}
