.comments-cell {
  padding-right: 16px;
  grid-column: 2;
  width: auto;
  min-width: 0;

  .comments {
    width: 100%;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    padding-bottom: 10px;
    margin-top: 12px !important;
    border-top: 1px solid #4a4e51;
    display: block;

    .comments-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: block;

      .comments-item {
        display: contents;
        font-size: 13px;
        color: rgb(36, 39, 41);

        .comment-text {
          min-width: 0;
          flex-basis: 0;
          padding: 6px;
          border-bottom: 1px solid #4a4e51;
          flex-grow: 1;
          font-size: 13px;
          line-height: 1.3;
          vertical-align: text-top;

          .comment-body {
            line-height: 1.7;
            word-wrap: break-word;

            .body {
              font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
            }

            .date {
              color: #9199a1;
              margin-left: 5px;
            }
          }

          .comment-links {
            padding: 0 4px 2px;
            color: #848d95;
            text-decoration: none;
            background-color: transparent;

            &:hover {
              color: #3c4146;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}