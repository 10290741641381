.post-form-container {
  width: 100%;
  height: calc(100vh - 64px);
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: 64px 0 0 0;
  background-color: #3d3d3d;
  position: relative;
  flex: 1 0 auto;
  color: #242729;

  .post-form-content {
    min-height: 750px;
    overflow: visible;
    width: 100%;
    margin: 0;
    background-color: transparent;
    padding-top: 0;
    padding-left: 24px;
    padding-right: 24px;

    .post-form-header {
      height: 130px;
      background-image: url("https://cdn.sstatic.net/img/ask/background.svg?v=2e9a8205b368");
      background-repeat: no-repeat;
      background-position: right bottom !important;
      padding-top: 24px !important;
      padding-bottom: 24px !important;
      padding-left: 4px;
      display: flex;
      align-items: center;

      .post-form-headline {
        font-size: 27px;
        color: #242729;
      }
    }

    .post-form-section {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      color: #242729;

      aside {
        flex-shrink: 0;
        color: #242729;

        .right-panel {
          width: 300px;
          margin-left: 24px;
        }
      }
    }
  }
}