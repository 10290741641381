.posts {
  padding: 12px 8px 12px 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid #4a4e51;

  .profile-tags {
    display: flex;
  }

  .stats {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .stats-container {
    width: 58px;
    color: #6a737c;
    margin-left: 20px;
    font-size: 11px;
  }
    .vote {
      padding: 0;
      margin-bottom: 8px;
      text-align: center;
      display: flex;

      .vote-count {
        font-size: 14px;
        margin-right: 2px;
      }

      .count-text {
        font-size: 12px;
      }
    }

    
    .answer {
      border: 2px solid #63b47c;
      background-color: #63b47c;
      color: white;
      border-radius: 3px;
      padding: 4px;

      .vote-count {
        color: white;
        font-size: 12px;
        padding: 1px;
      }
      .count-text{
        color: white;
        font-size: 12px;
        padding: 1px;
      }
    }


    .vote {
      padding: 0;
      margin-bottom: 8px;
      text-align: center;
      display: flex;

      .vote-count {
        font-size: 12px;
        margin-right: 2px;
      }

      .count-text {
        font-size: 12px;
      }

      .views {
        .count-text {
          font-size: 12px;
          color: #ffa600;
        }
      }

    }

    .summary {
      margin-left: 30px;
      width: 600px;

      h3 {
        font-weight: 400;
        font-size: 15px;
        line-height: 1.4;
        margin-bottom: 7.5px;

        a {
          color: #0077cc;
          line-height: 1.3;
          margin-bottom: 1.2em;
          font-size: 16px;
          text-decoration: none;

          &:hover {
            color: #0095ff;
          }
        }
      }

      .brief {
        padding: 0 0 5px 0;
        margin: 0;
        font-family: Arial, serif;
        font-size: 13px;
      }

      .question-user {
        width: 200px;
        line-height: 18px;
        float: right;

        .user-info {
          color: #848d95;
          padding: 5px 6px 7px 7px;

          .user-action-time {
            margin-bottom: 2px;
            margin-top: 1px;
            font-size: 12px;
          }

          .user-gravatar {
            float: left;
            width: 32px;
            height: 32px;
            border-radius: 1px;

            .logo-wrapper {
              padding: 0;
              overflow: hidden;

              img {
                width: 32px;
                height: 32px;
              }
            }
          }

          .user-details {
            margin-left: 40px;
            float: none;
            line-height: 17px;
            width: 80%;

            a {
              color: #0077cc;
              font-size: 12px;
              text-decoration: none;

              &:hover {
                color: #0095ff;
              }
            }
          }
        }
      }
    }
  }

@media (max-width: 420px) {
  .owner .user-block .user-profile .user-profile-link {
    font-size: 11px;
  }
}