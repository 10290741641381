.navbar {
  height: 63px;
  border-top: 3px solid #f48024;
  padding: 3px 3px 0 0;
  box-shadow: 5px 2px rgba(0,0,0,0.1);
  z-index: 1000;
  background-color: #3d3d3d;
  display: flex;
  justify-content: space-between;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.s-navigation {
  padding: 2px 2px;
}

.s-navigation .not-selected {
  color: #c4c8cc;

  &:hover {
    background-color: #404345;
    color: #f2f2f3;
    text-decoration: none;
    outline: none;
  }
}

* {
  box-sizing: border-box;
}

.navbar-brand {
  margin-left: 90px;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 0;

  &:hover {
    background-color: #404345;
  }
}

.btns {
  margin-right: 140px;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    width: 32px;
    border-radius: 3px;
    margin-right: 9px;
  }
}

.btn-sm {
  padding: 5px 10px;
}

.btn-outline-primary {
  background-color: #e1ecf4;
  color: #39739d;
  border-color: #7aa7c7;
}

.btn-outline-primary:hover {
  color: #2c5777;
  background-color: #b3d3ea;
  border-color: #7aa7c7;
}

.bar-items {
  font-size: 14px;
  color:  rgba(0, 0, 0, 0.6);
  margin: 0 10px;
}

.px12  {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.header-brand-div {
  display: flex;
  align-items: center;
}

.header-search-div {
  display: flex;
  justify-content: flex-end;
  align-items: center
}

.glyph-logo {
  display: none;
}

.search-icon {
  display: none;
  filter: invert(1);

  &:hover {
    cursor: pointer;
  }
}

.small-search {
  width: 98vw;
  position: fixed;
  top: 69px;
  left: 5px;
  border-radius: 8px;
}

.small-search-form {
  position: fixed;
  display: flex;
  z-index: 1000;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #00000042;
  box-shadow: 2px 2px 8px 3px gray;
}

.small-search-icon {
  position: fixed;
  top: 80px;
  right: 5%;
}

.s-input__search{
  margin-top: 10px;
}
.s-input-icon {
  margin-top: -2px;
}

// Side Navbar

.hamburger {
  display: none;
}

///////////////

@media(max-width: 1280px) {
  .navbar-brand {
    margin-left: 60px;
  }
}

@media(max-width: 1200px) {
  .btns {
    margin-right: 30px;
  }
}

@media (max-width:986px) {
  .navbar-brand {
    margin-left: 20px ;
  }
  .btns {
    margin-right: 20px;
  }
}

@media (max-width: 877px){
  .navbar-brand {
    margin-left: 0;
  }
  .header-brand-div {
    justify-content: flex-start;
    padding: 0 1.5rem;
  }
  .header-search-div {
    justify-content: space-around;
  }
  .searchbar {
      display: none;
    }
    .search-icon {
      display: block;
      margin-right: 1rem;
      position: absolute;
      left: 77%;
      top: 30%;
    }

  .btns {
    margin-right: 20px;
  }
  .s-input__search {
    max-width: 250px;
  }
}


@media (max-width: 715px) {
  .glyph-logo {
    display: block;
  }
  .full-logo {
    display: none;
  }
  .search-icon {
    display: block;
    position: relative;
    left: 15%;
    top: 30%;
  }
}

@media (max-width: 560px) {
  .glyph-logo {
    display: block;
  }
  .full-logo {
    display: none;
  }
  .s-navigation .s-navigation--item {
    display: none;

    &:first-of-type {
      display: inline;
    }
  }
  .search-icon {
    display: block;
    position: relative;
    left: 5%;
    top: 30%;
  }
  .hamburger {
    display: block;
    padding-top: 6px;
  }
  .header-search-div, .header-brand-div {
    transform: scale(0.8);
  }
}

@media (max-width: 420px) {
  .glyph-logo{
    display: none;
  }
  .s-navigation .s-navigation--item {
    &:first-of-type {
      margin-left: -4.7rem;
    }
  }
  .search-icon {
    display: block;
    position: relative;
    left: 5%;
    top: 30%;
  }
}

@media (max-width: 390px) {
  .navbar {
    padding: 0;
  }
}

@media (max-width: 345px) {
  .glyph-logo {
    display: none;
  }
}