.tag-content {
  box-sizing: inherit;
  margin-bottom: 8px;

  .tag-mult {
    margin-right: 4px;
    color: #848d95;

    span {
      font-size: 11px;
      color: #6a737c;
    }
  }
}