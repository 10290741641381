.user-panel-info {
  padding: 6px 7px 8px 8px;
  border: 1px solid #4a4e51;
  border-radius: 3px;
  background-color: #2d2d2d;
  box-sizing: border-box;
  color: #6a737c;
  display: flex;
  align-items: center;

  &:hover {
    border-color: #697075;
  }

  .user-gravatar {
    float: left;
    width: 48px;
    height: 48px;

    a {
      color: #0077cc;
      text-decoration: none;

      &:hover {
        color: #0095ff;
        text-decoration: none;
      }

      .logo-wrapper {
        width: 48px;
        height: 48px;
        border-radius: 2px;
        overflow: hidden;
        padding: 0;

        img {
          width: 48px;
          height: 48px;
          margin: 0 auto;
        }
      }
    }
  }

  .user-details {
    margin-left: 9px;
    width: calc(100% - 64px);
    line-height: 1.3;
    float: left;

    a {
      color: #0077cc;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;

      &:hover {
        color: #0095ff;
        text-decoration: none;
      }
    }

    .item {
      margin-right: 2px;
      display: block;
    }

    .user-time {
      display: flex;
      justify-content: space-between;
    }

    .count {
      font-size: 11px;
      font-weight: 700;
    }

    .count-info {
      font-size: 10px;
      font-weight: 500;
    }
  }
}