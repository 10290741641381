.grid-cell2 {
  margin: 12px;
  flex: 1 auto !important;
  color: #242729;

  .top-tags {
    margin-top: 17px;
    margin-bottom: 48px !important;

    h3 {
      font-weight: 700;
      color: #0c0d0e;
      padding-bottom: 8px !important;
      border-bottom: 1px solid #d6d9dc;
      font-size: 15px;
      margin-bottom: 15px;
    }

    .top-tags-sec {
      margin: -4px 0 -4px 0;
      display: flex;
      flex-direction: column;

      .top-tags-cells {
        margin: 4px;

        .top-cell {
          margin: -4px;
          display: flex;

          .tag-cell {
            margin: 4px;
            padding: 8px;
            display: flex;
            flex: 1 auto;
            background-color: #fafafb;
            border-radius: 3px;
            color: #242729;
          }

          .score {
            display: flex;
            justify-content: flex-end;
            flex: 1 auto;

            .score-txt {
              display: flex;

              .score-tab {
                margin: 0 8px 0 8px;
                height: 100%;
                font-size: 11px;
                display: flex;
                align-items: center;
                color: #9199a1;

                .txt {
                  text-transform: uppercase;
                  font-weight: 700;
                  margin-right: 6px;
                }

                .number {
                  color: #3c4146;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}