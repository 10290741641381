:root {
    --yellow-200: #675c37;
    --yellow-050: #464236;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Liberation Sans",sans-serif !important;
    transition: all 300ms ease-in-out;
}

body {
    background-color: #2d2d2d !important;
    padding-top: 0 !important;
}

a {
    text-decoration: none !important;
}

button {
    margin: 3px;
}