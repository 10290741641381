.owner {
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 3px;
  background-color: #3e4a52;
  text-align: left;
  vertical-align: top;
  width: 200px;

  .user-block {
    box-sizing: border-box;
    padding: 5px 6px 0 7px;
    color: #6a737c;

    .action-time {
      margin-top: 1px;
      margin-bottom: 4px;
      font-size: 12px;
      white-space: nowrap;
    }

    .user-logo {
      float: left;
      width: 32px;
      height: 32px;
      border-radius: 1px;
      margin-bottom: 6px;

      .user-link {
        color: #0077cc;
        text-decoration: none;
        cursor: pointer;

        .logo-wrapper {
          width: 32px;
          height: 32px;
          padding: 0;
          overflow: hidden;

          img {
            width: 32px;
            height: 32px;
            border-radius: 3px !important;
          }
        }
      }
    }

    .user-profile {
      margin-left: 8px;
      width: calc(100% - 40px);
      float: left;
      line-height: 17px;
      word-wrap: break-word;

      .user-profile-link {
        color: #0077cc;
        text-decoration: none;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          color:#0095ff;
        }
      }
    }
  }
}