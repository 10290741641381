.users-page {
  width: calc(100% - 300px - 24px);
  padding: 24px 0 24px 0;
  float: left;
  margin: 0;

  .headline {
    font-size: 28px;
    margin-bottom: 24px;
    line-height: 1.3;
    font-weight: 400;
    padding-left: 16px;
    padding-right: 16px;
  }

  .headline-count {
    margin-bottom: 12px;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;

    span {
      font-size: 17px;
      margin-right: 12px;
    }
  }

  .users-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: space-around;
  }

  .user-browser {
    float: none;
    clear: both;
    width: auto;
    padding: 0 16px 16px 16px;

    .grid-layout {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    }
  }
}

@media (max-width: 520px) {
  .users-box {
    .s-input {
      margin-bottom: 1rem;
    }
  }
}