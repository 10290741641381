.page {
  width: 100%;
  background: none;
  display: flex;
  justify-content: space-between;
  margin: 64px auto 0 auto;
}

#content {
  max-width: none;
  padding: 0;
}

#mainbar {
  padding-top: 14px;
}

.post {
  width: calc(100% - 300px - 24px);
  padding: 24px 0 24px 0;
  float: left;
  margin: 0;
  display: flex;
  flex-direction: column;

  .question-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: #fff;

    h1 {
      width: 80%;
      margin-bottom: 8px;
      font-size: 27px;
      font-weight: 400;
      font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
      flex: 1 auto !important;
      line-height: 1.3;
    }

    div {
      margin-left: 12px;
      color: #242729;

      .ask-button {
        color:#fff;
        background-color: #0095ff;
        box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.4);
        position: relative;
        display: inline-block;
        padding: .8em;
        border: 1px solid transparent;
        border-radius: 3px;
        outline: none;
        font-family: inherit;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.15384615;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        white-space: nowrap !important;

        &:hover {
          background-color: #0077cc;
        }
      }
    }
  }

  .question-date {
    padding-bottom: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;

    .grid-cell {
      white-space: nowrap;
      margin-bottom: 8px;
      margin-right: 16px;
      box-sizing: inherit;
      font-size: 13px;

      span {
        color: #6a737c;
        margin-right: 4px !important;
      }
    }
  }

  .question-main {
    float: none;
    clear: both;
    width: auto;
    margin-bottom: 20px;
    border-top: 1px solid #4a4e51;
  }
}

.fc-blue-600:hover {
  color: #378ad3 !important;
}

@media(max-width: 1100px) {
  .post {
    width: 80%;
  }
}