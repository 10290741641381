.answer {
  width: auto;
  float: none;
  padding-top: 10px;
  clear: both;
  color: #242729;

  .answer-header {
    width: 100%;
    margin-top: 10px;
    color: #fff;

    .answer-sub-header {
      margin-bottom: 8px !important;
      display: flex;
      align-items: center;

      .answer-headline {
        flex: 1 auto !important;

        h2 {
          font-weight: 400;
          margin-bottom: 0;
          font-size: 18px;
        }
      }
    }
  }

  .answers {
    border-bottom: 1px solid #4a4e51;
    width: 100%;
    padding-bottom: 16px;
    padding-top: 16px;
    color: #242729;
  }
}